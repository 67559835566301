// Colors
export const colors = {
  white: '#fff',
  black: '#000',
  thunder: '#231f20',
  emperor: '#52484b',
  blaze: '#ff5c0f',
  crusta: '#ff7c3f',
  athens: '#f1eff2',
  manatee: '#9295a5',
  manatee30: 'rgba(146, 149, 165, .3)',
  rose: '#e5396c',
  blush: '#ea6089',
};

// Vars
export const headerHeight = '100px';
export const borderRadius = '30px';
